<template>
  <a-modal
    :title="title"
    :visible="visible"
    @cancel="handleCancel"
    okText="Valider"
    @ok="handleOk"
    :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-form
      :form="form"
      :layout="formLayout"
      id="paiement_form"
      name="paiement_form"
      class="adherent_form"
    >
      <a-row :gutter="gutter">
        <a-col :span="span">
          <a-form-item label="Type paiement">
            <a-select
              @change="handleTypePaiementChange"
              v-decorator="[
                'type_reglement_id',
                {
                  rules: [{ required: true, message: 'Sélectionner un type' }],
                },
              ]"
              placeholder="Sélectionner un type"
            >
              <a-select-option
                v-for="type_paiement in type_paiements"
                :key="type_paiement.id"
                >{{ type_paiement.nom_fr }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="gutter">
        <a-col :span="span">
          <a-form-item label="Exercice">
            <a-select
              mode="multiple"
              :allow-clear="true"
              v-decorator="['exercice']"
              placeholder="Choisir un exercice"
              @change="handleExercicesSelectionChange"
            >
              <a-select-option :key="0">Choisir un exercice</a-select-option>
              <a-select-option v-for="exercice in exercices" :key="exercice.id"
                >{{ exercice.annee | year }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="gutter">
        <a-col :span="span">
          <a-form-item :label="getMontantLabel">
            <a-input-number
              style="width: 90%"
              v-decorator="[
                'montant',
                {
                  rules: [
                    { required: true, message: 'Le montant est obligatoire' },
                  ],
                },
              ]"
              placeholder="Entrer le Montant"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="gutter">
        <a-col :span="span">
          <a-form-item label="Date de paiement">
            <a-date-picker
              :format="dateFormat"
              style="width: 100%"
              v-decorator="[
                'dt_encaissement',
                {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'La date de paiement est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="gutter">
        <a-col :span="span">
          <a-form-item label="Mode paiement">
            <a-select
              @change="handlePaielmentMethodeChange"
              v-decorator="[
                'mode_paiement_id',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Sélectionner un mode de paiement',
                    },
                  ],
                },
              ]"
              placeholder="Sélectionner un mode de paiement"
            >
              <a-select-option
                v-for="mode_reglement in modes_reglement"
                :key="mode_reglement.id"
                >{{ mode_reglement.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="gutter" v-if="isChequePaiementSelected">
        <a-col :span="span">
          <a-form-item label="N° du chèque">
            <a-input
              v-decorator="[
                'num_cheque_recu',
                {
                  rules: [
                    { required: true, message: 'Le N° du chèque est obligatoire' },
                  ],
                },
              ]"
              placeholder="N° du chèque"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="gutter" v-if="isViremenPaiementSelected">
        <a-col :span="span">
          <a-form-item label="N° du virement">
            <a-input
              v-decorator="[
                'num_cheque_recu',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Le N° du virement est obligatoire',
                    },
                  ],
                },
              ]"
              placeholder="N° du virement"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="gutter" v-if="isEspecePaiementSelected">
        <a-col :span="span">
          <a-form-item label="N° du reçu">
            <a-input
              v-decorator="[
                'num_cheque_recu',
                {
                  rules: [
                    { required: true, message: 'Le N° du reçu est obligatoire' },
                  ],
                },
              ]"
              placeholder="N° du reçu"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="gutter">
        <a-col :span="span">
          <a-form-item label="Observation">
            <a-textarea
              v-decorator="[
                'observation',
                {
                  rules: [
                    { required: false, message: 'Observation est obligatoire' },
                  ],
                },
              ]"
              placeholder="Observation"
            ></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <template slot="footer">
      <a-button
        key="submit"
        type="primary"
        :loading="processing"
        @click="handleOk"
        >Valider</a-button
      >
    </template>
  </a-modal>
</template>
<script>
import _filter from "lodash-es/filter";
import _flatten from "lodash-es/flatten";
import _sumBy from "lodash-es/sumBy";
import _map from "lodash-es/map";
import _find from "lodash-es/find";
import _get from "lodash-es/get";

const span = 16;
const gutter = 12;
const ESPECE = 2;
const VIREMENT = 1;
const CHEQUE = 3;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 12,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 20,
    },
  },
};

export default {
  name: "CotisationEditor",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  mounted() {
    if (this.editingMode) {
      this.setPaiementType(this.paiement.mode_paiement.id)
      this.fillingTheForm(this.paiement)
      this.selectedTypePaiement = this.paiement.type_reglement.id 
      this.handleExercicesSelectionChange(this.paiement.exercices)
    }
  },
  data() {
    return {
      formItemLayout,
      tailFormItemLayout,
      gutter,
      span,
      ESPECE,
      CHEQUE,
      VIREMENT,
      paiement_type: ESPECE,
      selectedTypePaiement: null,
      sum: null,
      selectedExercices : null,
      showExerciceInput : false
    };
  },
  props: [
    "visible",
    "exercices",
    "paiement",
    "processing",
    "modes_reglement",
    "type_paiements",
  ],
  computed: {
    getMontantLabel() {
      return this.sum ? `Montant (${this.sum} DA)` : "Montant";
    },
    title() {
      return this.get(this, 'adherant.full_name');
    },
    editingMode() {
      return this.paiement;
    },
    isChequePaiementSelected() {
      return this.paiement_type === this.CHEQUE;
    },
    isEspecePaiementSelected() {
      return this.paiement_type === this.ESPECE;
    },
    isViremenOrChequetPaiementSelected() {
      return (
        this.paiement_type === this.VIREMENT ||
        this.paiement_type === this.CHEQUE
      );
    },
    isViremenPaiementSelected() {
      return this.paiement_type === this.VIREMENT;
    },
  },
  methods: {
    handleTypePaiementChange(value) {
      this.selectedTypePaiement = value
      this.showExerciceInput = true 
      this.handleExercicesSelectionChange(this.selectedExercices)
    },
    handleExercicesSelectionChange(selectedExercices) {
      this.selectedExercices = selectedExercices
      if (!this.selectedTypePaiement || !this.selectedExercices) {
        return;
      }
    
      let a = _filter(this.exercices, (e) =>
        _find(this.selectedExercices, (o) => e.id === o)
      );
     

      let b = _flatten(
        _map(a, function (value, key) {
          return value.type_reglements;
        })
      );

      const selectedCode = _get(_find(this.type_paiements, { id: this.selectedTypePaiement }), 'code');

      let c = _filter(b, (e) => e.code === selectedCode)

      this.sum = _sumBy(c, function (o) {
        return o.montant;
      });
    },
    setPaiementType(paiementType) {
      this.paiement_type = paiementType;
    },
    handleClose() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("close");
    },
    handleOk() {
      this.$emit("confirme");
    },
    handlePaielmentMethodeChange(value) {
      this.paiement_type = value;
    },
    handleUploadChange(info) {
      if (info.file.status === "done") {
        this.$_showSuccessMessage(
          `${info.file.name} file uploaded successfully`
        );
      }
      if (info.file.status === "error") {
        this.$_throwAnError(`Echec de l'importation.`);
      }
    },
    fillingTheForm(paiement) {
      this.$nextTick(() => {
        this.form.setFieldsValue(paiement);
        this.form.setFieldsValue({ montant: paiement.montant })
        this.form.setFieldsValue({ exercice: paiement.exercices })
        this.form.setFieldsValue({
          mode_paiement_id: paiement.mode_paiement.id,
        });
        this.form.setFieldsValue({
          dt_encaissement: this.momentDate(paiement.dt_encaissement),
          type_reglement_id: paiement.type_reglement.id,
        });
      });
    },
  },
};
</script>